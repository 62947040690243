'use client'

import { Link } from '@shc/ui'

function ScrollToTop() {
  return (
    <Link
      as="button"
      className="no-underline"
      tabIndex={0}
      onClick={() =>
        window.scrollTo({
          behavior: 'smooth',
          top: 0,
        })
      }>
      Back to top
    </Link>
  )
}

export default ScrollToTop
